import { getTodaysGameId, dateString } from "@oojii/lib/utils";
import APIClient from "@oojii/lib/net/api-client";

type HiScore = {
  username: string;
  score: number;
};

class GameBase {

  static concreteClass;
  
  static init(concreteClass: any) {
    GameBase.concreteClass = concreteClass;
  }

  static create(id: string, data:any) {
    return new GameBase.concreteClass(id, data);
  }

  id: string;
  date: Date;
  globalHiscores: HiScore[];

  constructor(id: string) {
    this.id = id;
    this.date = new Date(id);
    this.globalHiscores = [];
  }

  name(): string {
    if (this.isTodaysGame()) {
      return "Today's Game";
    }
    return dateString(this.date);
  }

  // async loadHiScores() {
  //   const res = await APIClient.getHiScores(this.id);
  //   this.globalHiscores = res.scores;
  //   return this.globalHiscores;
  // }

  isTodaysGame() {
    return this.id === getTodaysGameId();
  }
}

export { HiScore };
export default GameBase;
