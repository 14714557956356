import { EventDispatcher } from "@oojii/lib/utils";
import GameBase from "./game-base";

class GameStateBase extends EventDispatcher {
  static concreteClass;

  static init(concreteClass: any) {
    GameStateBase.concreteClass = concreteClass;
  }

  static create() {
    console.log("create game state");
    return new GameStateBase.concreteClass();
  }

  game: GameBase;
  finished: boolean;

  static events = {
    UPDATE: "UPDATE",
    COMPLETE: "COMPLETE",
    END: "END",
    PAUSE: "PAUSE",
    PROMPT_HINT: "PROMPT_HINT",
  };

  constructor() {
    super();
    this.finished = false;
  }

  setGame(game: GameBase) {
    this.game = game;
  }

  getState() {
    throw new Error("Override in subclass");
  }

  setState(state) {
    throw new Error("Override in subclass");
  }

  completeGame() {
    this.finished = true;
    this.dispatch(GameStateBase.events.COMPLETE, this);
  }

  endGame() {
    this.finished = true;
    this.dispatch(GameStateBase.events.END, this);
  }

  dispatchUpdate() {
    this.dispatch(GameStateBase.events.UPDATE, this);
  }

  restart() {
    this.setGame(this.game);
  }

  pause(): void {
    this.dispatch(GameStateBase.events.PAUSE, this);
  }

  promptHint(): void {
    this.dispatch(GameStateBase.events.PROMPT_HINT, this);
  }

  // getFinalScore() {
  //   return Math.round(this.score * this.scoreMultiplier);
  // }
}

export default GameStateBase;
