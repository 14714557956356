const prepareGamePreviewImages = () => {
  const previewImages: HTMLCollectionOf<Element> =
    document.getElementsByClassName("preview-images");
  const arr: Element[] = Array.from(previewImages);
  arr.forEach((previews) => {
    const images = previews.getElementsByTagName("img");
    (previews as HTMLElement).style.height = `${
      (images.length - 1) * 80 + 280
    }px`;
    Array.from(images).forEach((img, index) => {
      img.style.top = `${index * 80}px`;
      img.style.rotate = `${index % 2 === 0 ? 7 : -7}deg`;
    });
  });
};

export default prepareGamePreviewImages;
