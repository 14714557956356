const prepareLogo = () => {
  const logo = document.getElementById("logo");
  const path = document.getElementById("logo-path");

  if (!logo || !path) {
    return;
  }
  const layerColours = ["#1e3ed9", "#FF0075", "#5aeb50", "#ffb700", "#00bbff"];

  const PATH_BUMP = 20;
  const FADE_IN = 0.5;
  const TIME = 600;
  const START_OPACITY = 0.2;

  const paths = layerColours.map((c, i) => {
    const newPath: HTMLElement = path.cloneNode(true) as HTMLElement;
    newPath.setAttribute("stroke-width", String((i + 2) * PATH_BUMP));
    newPath.setAttribute("stroke", c);
    newPath.style.opacity = String(START_OPACITY);
    newPath.style.transition = `opacity ${FADE_IN}s ease-in-out`;
    logo.insertBefore(newPath, logo.firstChild);
    return newPath;
  });

  const switchOn = (index) => {
    const p = paths[index];
    if (p) {
      p.style.opacity = String(1);
    }
    setTimeout(() => {
      if (p) {
        switchOn(index + 1);
      } else {
        paths.forEach((p) => (p.style.opacity = String(START_OPACITY)));
        setTimeout(() => {
          switchOn(0);
        }, TIME);
      }
    }, TIME);
  };

  setTimeout(() => {
    switchOn(0);
  }, TIME);
};

export default prepareLogo;
