class EventDispatcher {

  static mixin(target) {
    const eventDispatcher = new EventDispatcher();
    target.addListener = eventDispatcher.addListener.bind(eventDispatcher);
    target.removeListener = eventDispatcher.removeListener.bind(eventDispatcher);
    target.hasListener = eventDispatcher.hasListener.bind(eventDispatcher);
    target.dispatch = eventDispatcher.dispatch.bind(eventDispatcher);
  }

  listenerHash: Object;

  constructor() {
    this.listenerHash = {};
  }

  addListener(type, callback) {
    if (type === undefined) {
      throw new Error('Cannot add an "undefined" listener.');
    }
    if (this.listenerHash[type] === undefined) {
      this.listenerHash[type] = [];
    }
    this.listenerHash[type].push(callback);
  }

  hasListener(type, callback) {
    const listeners = this.listenerHash[type] || [];
    const listener = listeners.find((cb) => cb !== callback);
    return listener !== undefined && listener !== null;
  }

  removeListener(type, callback) {
    const listeners = this.listenerHash[type] || [];
    this.listenerHash[type] = listeners.filter((cb) => cb !== callback);
  }

  dispatch(type, ...rest) {
    const listeners = this.listenerHash[type] || [];
    listeners.forEach((callback) => {
      callback.apply(null, rest);
    });
  }
}

const GlobalDispatcher = new EventDispatcher();
export { GlobalDispatcher };

export default EventDispatcher;
