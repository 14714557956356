import { GameBase } from "@oojii/lib/model";

const GET = "GET";
const POST = "POST";
// const PUT = "PUT";
// const DELETE = "DELETE";

class HTTPError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

const fetchJSON = async (url, method = GET, data = {}) => {
  const params = {
    method,
    credentials: "include" as RequestCredentials,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (method !== GET && data) {
    params["body"] = JSON.stringify(data);
  }
  const response = await fetch(url, params);
  const json = await response.json();
  if (response.status >= 300) {
    throw new HTTPError(
      json.message || `Error making call ${url}`,
      response.status
    );
  }
  return json;
};

class APIClient {
  static instance: APIClient;

  static init(gameName: string, apiHost: string) {
    APIClient.instance = new APIClient(gameName, apiHost);
  }

  static getInstance() {
    if (!APIClient.instance) {
      throw new Error(
        "APIClient not initialized.\nCall APIClient.init(gameName) first."
      );
    }
    return APIClient.instance;
  }

  static async getGame(gameId): Promise<any> {
    const inst = APIClient.getInstance();
    const data = await inst.httpGet(
      `games/${inst.gameName}/games/${gameId}.json`,
      "/"
    );
    return GameBase.create(gameId, data);
  }

  static async getHiScores(gameId: string): Promise<any> {
    const inst = APIClient.getInstance();
    const game = await inst.httpGet(
      `games/${inst.gameName}/hi-scores/${gameId}.json?r=${Math.random()}`,
      "/"
    );
    return game;
  }

  static async postHiScore(
    gameId: string,
    score: number,

    moves: Array<any>,
    username: string
  ) {
    const inst = APIClient.getInstance();
    const result = await inst.httpPost(`hi-scores/${inst.gameName}/${gameId}`, {
      score,
      gamePlay: moves,
      username,
    });
    return result;
  }

  gameName: string;
  apiHost: string;

  constructor(gameName, apiHost) {
    this.gameName = gameName;
    this.apiHost = apiHost;
  }

  httpGet(path, url = this.apiHost) {
    return fetchJSON(url + path, GET);
  }

  httpPost(path, data = {}, url = this.apiHost) {
    return fetchJSON(url + path, POST, data);
  }
}

export default APIClient;
