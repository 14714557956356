import { getTodaysGameId } from "@oojii/lib/utils";
import APIClient from "@oojii/lib/net/api-client";
// import { create as createHTTP } from "@oojii/lib/utils/net";

// const { httpGet } = createHTTP("/");

type Score = {
  username: string;
  score: number;
};

const renderForDate = async (date: string) => {
  renderTitle(date);
  renderPrevNext(date, "hi-scores-prev", -1);
  renderPrevNext(date, "hi-scores-next", 1);

  const container = document.getElementById("hi-scores-list");
  container.innerHTML = "";
  try {
    // const result = await httpGet(
    //   `${globalThis.gameName}/hi-scores/${date}.json?r=${Math.random()}`
    // );

    const result = await APIClient.getHiScores(date);
    const scores: Score[] = result.scores;
    renderScores(scores, container);
  } catch (e) {
    console.log(e);
    container.innerHTML = "No scores for this date";
  }
};

const renderPrevNext = (date: string, id: string, offset: number) => {
  const prev = document.getElementById(id);
  prev.onclick = () => {
    const d = new Date(date);
    d.setDate(d.getDate() + offset);
    renderForDate(d.toISOString().split("T")[0]);
  };
};

const renderTitle = (date: string) => {
  const dateText = document.getElementById("hi-scores-date");
  const s = new Date(date).toDateString();
  dateText.innerHTML = `Scores for ${s}`;
};

const renderScores = (scores: Score[], container: HTMLElement) => {
  if (scores.length === 0) {
    container.innerHTML =
      "No scores yet<br/>Be the first to submit a high score!";
    return;
  }

  let html =
    '<table class="hi-score-table"><tr><th>Rank</th><th>Name</th><th>Score</th></tr>';

  scores.forEach((score, index) => {
    html += `<tr><td>${index + 1}</td><td>${score.username}</td><td>${
      score.score
    }</td></tr>`;
  });
  html += "</table>";
  container.innerHTML = html;
};

const prepareHiScores = () => {
  const hiScores = document.getElementById("hi-scores");
  if (!hiScores) {
    return;
  }

  APIClient.init(globalThis.gameName, "");

  const searchParams = new URLSearchParams(window.location.search);
  let date = searchParams.get("date");
  if (!date) {
    date = getTodaysGameId();
  }

  renderForDate(date);
};

export default prepareHiScores;
