import Dictionary from "./dictionary";
import EventDispatcher from "./event-dispatcher";
import Rand from "./rand";
import Colour from "./colour";
import ConsoleColors from "./console-colors";

const getGameIdFromDate = (date: Date): string => {
  const id = `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )}`;
  return id;
};

const getTodaysGameId = (): string => {
  return getGameIdFromDate(getTodaysDate());
};

const getTodaysDate = (): Date => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

const pad = (num: number): string => {
  return padZeros(num, 2);
};

const padZeros = (value: number, length: number): string => {
  let str = String(value);
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
};

const leadingZero = (number) => {
  if (number >= 10) {
    return String(number);
  } else {
    return `0${number}`;
  }
};

const chars = (char, times) => {
  return doTimes(times, () => {
    return char;
  }).join("");
};

const spaceRight = (str, endLength, trimIfLonger = true, char = " ") => {
  if (str.length > endLength) {
    if (trimIfLonger) {
      return str.slice(0, endLength);
    } else {
      return str;
    }
  }

  return str + chars(char, endLength - str.length);
};

const sleep = (millis: number) => {
  return new Promise((resolve: Function) => {
    setTimeout(() => {
      resolve();
    }, millis);
  });
};

const setValues = (target, vals) => {
  for (const key in vals) {
    target[key] = vals[key];
  }
};

const usernameRegex = /^[a-zA-Z0-9]{3,20}$/;

const checkUsername = (username) => {
  return usernameRegex.test(username);
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dateString = (date: Date): string => {
  return `${
    months[date.getUTCMonth()]
  } ${date.getUTCDate()} ${date.getUTCFullYear()}`;
};

const shortDateString = (date: Date): string => {
  return `${months[date.getUTCMonth()]} ${date.getUTCDate()}`;
};

interface callbackInterface {
  (index: number): unknown;
}

const doTimes = (times: number, callback: callbackInterface): Array<any> => {
  let arr: unknown[] = [];
  for (var i = 0; i < times; i++) {
    arr.push(callback(i));
  }
  return arr;
};

const pluralise = (count, singular, plural) => {
  if (count === 0 || count > 1) {
    return plural;
  }
  return singular;
};

const daysAgo = (num: number): Date => {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() - num);
  return date;
};

export {
  getGameIdFromDate,
  getTodaysGameId,
  getTodaysDate,
  pad,
  padZeros,
  leadingZero,
  doTimes,
  chars,
  spaceRight,
  sleep,
  setValues,
  checkUsername,
  dateString,
  shortDateString,
  EventDispatcher,
  Dictionary,
  Rand,
  Colour,
  ConsoleColors,
  pluralise,
  daysAgo,
};
